<template>
  <a-select
    allowClear
    style="width: 100%"
    :disabled="disabled"
    v-model="selectedWarehouse"
    :placeholder="selfPlaceholder"
    :not-found-content="loading ? undefined : null"
    @change="selectWarehouse"
    @focus="fetchWarehouseList"
  >
    <a-spin v-if="loading" slot="notFoundContent" size="small" />
    <a-select-option v-for="item in warehouseList" :value="item.code" :key="item.code">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
import http from '../service/axios'
import api from '../service/api'

export default {
  props: ['placeholder', 'disabled'],
  data() {
    return {
      warehouseList: [],
      loading: false,
      selectedWarehouse: undefined,
    }
  },
  computed: {
    selfPlaceholder() {
      return this.placeholder || this.$t('请选择仓库')
    },
  },
  methods: {
    async fetchWarehouseList() {
      if (this.warehouseList.length) return
      this.loading = true
      let res = await http({
        url: api.getWarehouseListNew,
        type: 'post',
        data: {
          merchantCode: this.$store.getters.merchantCode,
        },
      })
      this.loading = false
      this.warehouseList = res.result.list
    },
    selectWarehouse(val) {
      if (val) {
        let data = this.warehouseList.find((item) => item.code === val)
        this.$emit('selected', data)
      }
    },
  },
}
</script>
