<template>
  <a-select
    style="width: 100%"
    :mode="mode"
    allowClear
    v-model="selectedWarehouse"
    :not-found-content="loading ? undefined : null"
    :placeholder="$t('请选择仓库货主')"
    @change="selectWarehouse"
    @focus="fetchWarehouseShipperList"
  >
    <a-spin v-if="loading" slot="notFoundContent" size="small" />
    <a-select-option
      v-for="item in warehouseList"
      :value="`${item.code}/${item.wmsAppCode}/${item.name}`"
      :key="item.id"
    >
      <a-tooltip placement="topLeft">
        <template slot="title">{{ item.name }}</template>
        {{ item.wmsAppCode }} - {{ item.name }}
      </a-tooltip>
    </a-select-option>
  </a-select>
</template>

<script>
import http from '../service/axios'
import api from '../service/api'

export default {
  props: ['mode', 'wmsAppCode'],

  data() {
    return {
      warehouseList: [],
      selectedWarehouse: undefined,
      loading: false,
    }
  },
  methods: {
    async fetchWarehouseShipperList() {
      if (this.warehouseList.length) return
      this.loading = true
      let res = await http({
        url: api.getWarehouseShipperList,
        type: 'post',
        data: {
          wmsAppCode: this.wmsAppCode,
          merchantCode: this.$store.getters.merchantCode,
        },
      })
      this.warehouseList = res.result.list
      this.loading = false
    },
    selectWarehouse() {
      if (this.selectedWarehouse != undefined) {
        if (this.mode == 'multiple') {
          this.$emit('selected', {
            shipperList: this.selectedWarehouse.map((item) => {
              let arr = item?.split('/')
              return {
                shipperCode: arr[0],
                wmsAppCode: arr[1],
                shipperName: arr[2],
              }
            }),
          })
        } else {
          let arr = this.selectedWarehouse?.split('/')
          this.$emit('selected', {
            shipperList: {
              shipperCode: arr[0],
              wmsAppCode: arr[1],
              shipperName: arr[2],
            },
          })
        }
      } else {
        this.$warning(this.$t('请选择仓库货主'))
      }
    },
  },
}
</script>
