<template>
  <div class="publish-goods-modal">
    <a-modal
      :width="700"
      v-model="visiable"
      :destroyOnClose="true"
      :bodyStyle="bodyStyles"
      :title="$t(`发布商品`)"
      :confirmLoading="publishLoading"
      @ok="publishGoods"
    >
      <div>
        <a-form-model-item required :label="$t(`发布平台`)">
          <a-select
            style="width: 100%"
            v-model="selectedPlatform"
            :placeholder="$t('请选择仓库货主')"
          >
            <a-select-option :value="1">{{ $t(`仓库`) }}</a-select-option>
            <a-select-option :value="0">{{ $t(`卖家平台`) }}</a-select-option>
            <!-- <a-select-option :value="2">{{ $t(`供销平台`) }}</a-select-option> -->
          </a-select>
        </a-form-model-item>
      </div>
      <template v-if="selectedPlatform === 1">
        <a-form-model-item required :label="$t(`仓库列表`)">
          <SelectWarehouseNew @selected="selectedWarehouse" />
        </a-form-model-item>
        <a-form-model-item required :label="$t(`仓库货主`)">
          <SelectWarehouseShipper
            :wmsAppCode="wmsAppCode"
            mode="multiple"
            @selected="selectedWarehouseShipper"
          />
        </a-form-model-item>
      </template>
      <div v-else>
        <a-form-model v-bind="formItemLayout">
          <a-form-model-item :label="$t(`店铺`)">
            <SelectShop
              ref="selectShop"
              :init="true"
              :code.sync="modalData.shopCode"
              @selected="selectedShop"
            />
          </a-form-model-item>
          <a-form-model-item :label="$t(`品牌`)">
            <SelectGoodsBrand
              ref="selectGoodsBrand"
              :placeholder="'--' + $t(`无品牌`) + '--'"
              :goodsId="goodsId"
              :shopCode="modalData.shopCode"
              :platform="modalData.platform"
              :select="modalData.brand"
              @selected="selectGoodsBrand"
            />
          </a-form-model-item>
          <a-form-model-item v-if="category_text" :label="$t('当前商品的类目是')">
            <a-input disabled placeholder="" class="text-main" v-model="category_text" />
          </a-form-model-item>
          <div class="modal-attr-list">
            <a-form-model-item
              class="attr-item"
              v-for="item in publishAttributes"
              :key="item.name"
              :required="!!item.isMandatory"
            >
              <template slot="label">
                <span class="key-icon" v-if="item.advanced.isKeyProp">key</span>
                <span>{{ item.label }}</span>
              </template>
              <PublishModalInput
                :options="item.options"
                :type="item.inputType"
                :name="item.name"
                :shopCode="modalData.shopCode"
                :value.sync="modalData.goodsAttributes[item.name]"
              ></PublishModalInput>
            </a-form-model-item>
          </div>
        </a-form-model>
        <div class="modal-table" v-show="publishSkuList.length">
          <a-table
            :columns="modalColumns"
            :data-source="publishSkuList"
            rowKey="skuId"
            :pagination="false"
            bordered
            :scroll="{ x: modalSkuAttrList.length * 250 + 100 }"
          >
            <div
              style="width: 100%; display: flex"
              v-for="(item, index) in modalSkuAttrList"
              :key="index"
            >
              <template slot-scope="text, scope" :slot="item.title">
                <PublishModalInput
                  :key="item.title"
                  :type="item.inputType"
                  :options="item.options"
                  :value.sync="scope.skuAttributes[item.title]"
                  @changeValue="changeSkuValue(scope.skuId, item.title, $event)"
                ></PublishModalInput>
                <i class="red" v-if="item.isMandatory">*</i>
              </template>
            </div>
          </a-table>
        </div>
      </div>
    </a-modal>
    <a-modal v-model="resultModalVisible" :title="$t('执行结果')" :footer="null">
      <div class="modal-block" v-if="Object.keys(resultInfo.successMap).length > 0">
        <div class="block-title">{{ $t('成功列表') }}</div>
        <div class="block-body">
          <div class="result-item mt6" v-for="(item, key) in resultInfo.successMap" :key="key">
            <div class="shop-name">
              {{ selectedPlatform === 1 ? $t('仓库货主') : $t('店铺名') }}：{{
                shipperMapping[key]
              }}
            </div>
            <div class="code">{{ $t('编码') }}：{{ item.join(', ') }}</div>
          </div>
        </div>
      </div>
      <div class="modal-block mt10" v-if="Object.keys(resultInfo.failedMap).length > 0">
        <div class="block-title">{{ $t('失败列表') }}</div>
        <div class="block-body">
          <div class="result-item mt6" v-for="(item, key) in resultInfo.failedMap" :key="key">
            <div class="shop-name">
              {{ selectedPlatform === 1 ? $t('仓库货主') : $t('店铺名') }}：{{
                shipperMapping[key]
              }}
            </div>
            <div class="code">{{ $t('编码') }}：{{ item.join(', ') }}</div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import http from '@service/axios'
import api from '@service/api'
import SelectShop from '@component/selectShop'
import SelectGoodsBrand from '@component/selectGoodsBrand'
import PublishModalInput from '@component/publishModalInput'
import SelectWarehouseShipper from '@component/selectWarehouseShipper'
import SelectWarehouseNew from '@component/selectWarehouseNew'
export default {
  name: 'publishGoodsModal',
  props: ['publishVisible', 'goodsId', 'categoryId', 'modalSkuId', 'type'],
  data() {
    return {
      formItemLayout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 },
      },
      modalData: {
        shopCode: undefined,
        goodsId: [],
        brand: undefined,
        goodsAttributes: {},
        skuList: [],
      },
      bodyStyles: {
        // height: '550px',
        overflowY: 'scroll',
      },
      modalSkuAttrList: [],
      publishAttributes: [],
      publishSkuList: [],
      category_text: '',
      selectedPlatform: 1,
      publishLoading: false,
      warehouseShipper: null,
      resultModalVisible: false,
      shipperMapping: {},
      resultInfo: {
        successMap: {},
        failedMap: {},
      },
      wmsAppCode: undefined,
    }
  },
  components: {
    SelectShop,
    SelectGoodsBrand,
    PublishModalInput,
    SelectWarehouseShipper,
    SelectWarehouseNew,
  },
  computed: {
    modalColumns() {
      let columns = [
        {
          title: 'skuId',
          dataIndex: 'skuId',
          key: 'skuId',
          width: 100,
          fixed: 'left',
        },
      ]
      let modalSkuAttrList = []
      if (this.publishSkuList.length) {
        this.publishSkuList[0].styles.forEach((item) => {
          let obj = {
            key: item.styleType,
            title: item.styleType,
            dataIndex: item.styleType,
          }
          modalSkuAttrList.push(obj)
        })
        this.publishSkuList[0].attributes.forEach((item) => {
          let obj = {
            key: item.name,
            title: item.name,
            dataIndex: item.name,
            scopedSlots: { customRender: item.name },
            options: item.options,
            inputType: item.inputType,
            isMandatory: !!item.isMandatory,
            width: 250,
          }
          modalSkuAttrList.push(obj)
        })
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.modalSkuAttrList = modalSkuAttrList
      return [...columns, ...modalSkuAttrList]
    },
    visiable: {
      get() {
        return this.publishVisible
      },
      set(val) {
        this.$emit('update:publishVisible', val)
      },
    },
  },
  watch: {
    publishVisible(newVal) {
      if (newVal) {
        this.clear()
        this.$refs?.selectShop?.reset()
      }
    },
  },
  methods: {
    selectedWarehouse(data) {
      this.wmsAppCode = data.wmsAppCode
    },
    selectedWarehouseShipper(val) {
      this.warehouseShipper = val
      val.shipperList.forEach((item) => {
        this.shipperMapping[item.shipperCode] = item.shipperName
      })
    },
    clear() {
      this.category_text = undefined
      this.modalData.goodsId = this.goodsId
      this.modalData.brand = undefined
      this.modalData.shopCode = undefined
      this.modalData.goodsAttributes = {}
      this.modalData.skuList = []
      this.publishAttributes = []
      this.modalSkuAttrList = []
      this.publishSkuList = []
      this.category_text = undefined
    },
    selectedShop(data) {
      this.clear()
      this.modalData.shopCode = data.code
      this.modalData.platform = data.platform
      this.queryPublishAttribute()
    },
    selectGoodsBrand(data, name) {
      if (data) {
        this.modalData.brand = name
      } else {
        this.modalData.brand = ''
      }
    },
    queryPublishAttribute() {
      if (!this.modalData.goodsId) return
      if (!this.modalData.shopCode) return
      http({
        instance: this,
        type: 'post',
        url: api.queryPublishAttribute,
        data: {
          goodsId: this.modalData.goodsId,
          shopCode: this.modalData.shopCode,
        },
        hasLoading: true,
        success: (res) => {
          this.category_text = res?.result?.categoryChain
          this.publishAttributes = res?.result?.attributes || []
          this.modalData.brand = res?.result?.platformBrand
          this.$refs['selectGoodsBrand'].fetchBrandList(this.modalData.brand)
          // this.publishAttributes = res.result.platformBrand;
          let skuList = res?.result?.skuList || []
          skuList?.forEach((item) => {
            item.styles.forEach((elem) => {
              item[elem.styleType] = elem.styleValue
            })
            item.skuAttributes = {}
          })
          this.publishSkuList = skuList
        },
      })
    },
    changeSkuValue(skuId, type, value) {
      let skuItem = this.modalData.skuList.find((item) => item.skuId === skuId)
      if (skuItem) {
        skuItem.skuAttributes[type] = value
      } else {
        skuItem = {
          skuId,
          skuAttributes: {},
        }
        skuItem.skuAttributes[type] = value
        this.modalData.skuList.push(skuItem)
      }
    },
    async publishGoods() {
      if (this.selectedPlatform === 1 && !this.warehouseShipper) {
        this.$message.warning(this.$t('请选择仓库货主'))
        return
      }
      if (this.selectedPlatform) {
        this.publishLoading = true
        let url =
          this.type === 'productGroups' ? api.batchCreateWmsGroupGoods : api.batchCreateWmsGoods
        let res = await http({
          url,
          type: 'post',
          data: {
            skuIds: this.modalSkuId,
            shipperList: this.warehouseShipper.shipperList,
          },
          hasLoading: false,
        })
        console.log('res: ', res)
        this.publishLoading = false
        this.visiable = false
        if (res.success) {
          this.resultInfo = res.result
          this.resultModalVisible = true
        }
      } else {
        let err = ''
        this.publishAttributes.forEach((item) => {
          if (item.isMandatory && !this.modalData.goodsAttributes[item.name]) {
            err = `${item.name}${this.$t('必填')}`
          }
        })
        if (err) {
          this.$warning(err)
          return
        }
        // if (!this.modalData.brand) {
        //   this.$warning(this.$t(`请选择品牌`))
        //   return
        // }
        this.modalData.skuList = this.modalSkuId.map((item) => ({
          skuId: item,
          skuAttributes: {},
        }))
        this.$emit('publishGoods', this.modalData)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@css/mixin.scss';

.card {
  width: 100%;
  margin: 0 auto 20px;
  background-color: #f7f8fa;
  padding: 10px;
  font-size: 12px;
  color: #666;
}

.ant-form-item {
  margin-bottom: 0;
}

.modal-table {
  margin-top: 10px;
}

.key-icon {
  font-size: 10px;
  padding: 2px 6px;
  margin-right: 6px;
  border-radius: 4px;
  background-color: $warning-color;
  color: $white;
}

// ::v-deep .ant-modal-body {
//   height: 550px;
//   overflow-y: auto;
// }

.block-title {
  font-size: 16px;
  font-weight: bold;
}
</style>
